var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.iconComponent, {
    tag: "component",
    staticClass: "custom-svg-icon",
    attrs: { role: "img" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }