var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "loading-icon__svg loading-clock",
      attrs: {
        focusable: "false",
        role: "img",
        xmlns: "http://www.w3.org/2000/svg",
        x: "0px",
        y: "0px",
        viewBox: "0 0 384 384",
        width: "35",
        height: "35",
        "xml:space": "preserve",
      },
    },
    [
      _c("title", [_vm._v(_vm._s(_vm.getDictionary("loading")))]),
      _vm._v(" "),
      _c("g", [
        _c("circle", {
          staticClass: "loading-clock-frame",
          attrs: {
            cx: "192",
            cy: "192",
            r: "172",
            fill: "none",
            stroke: "currentColor",
            "stroke-width": "25",
          },
        }),
        _vm._v(" "),
        _c(
          "line",
          {
            staticClass: "loading-clock-hour-hand",
            attrs: {
              id: "loading-anim-clock-hour-hand",
              fill: "none",
              stroke: "currentColor",
              "stroke-linecap": "round",
              "stroke-width": "20",
              "stroke-miterlimit": "10",
              x1: "192",
              y1: "192",
              x2: "192",
              y2: "130",
            },
          },
          [
            _c("animateTransform", {
              attrs: {
                type: "rotate",
                fill: "remove",
                restart: "always",
                calcMode: "linear",
                accumulate: "none",
                additive: "sum",
                repeatCount: "indefinite",
                dur: "12s",
                to: "360 192 192",
                from: "0 192 192",
                attributeName: "transform",
                attributeType: "xml",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "line",
          {
            staticClass: "loading-clock-minute-hand",
            attrs: {
              id: "loading-anim-clock-minute-hand",
              fill: "none",
              stroke: "currentColor",
              "stroke-linecap": "round",
              "stroke-width": "20",
              "stroke-miterlimit": "10",
              x1: "192",
              y1: "192",
              x2: "192",
              y2: "90",
            },
          },
          [
            _c("animateTransform", {
              attrs: {
                type: "rotate",
                fill: "remove",
                restart: "always",
                calcMode: "linear",
                accumulate: "none",
                additive: "sum",
                repeatCount: "indefinite",
                dur: "3s",
                to: "360 192 192",
                from: "0 192 192",
                attributeName: "transform",
                attributeType: "xml",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("circle", {
          staticClass: "loading-clock-axis",
          attrs: {
            fill: "none",
            stroke: "none",
            cx: "192",
            cy: "192",
            r: "15",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }